
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['plan-completed'],
  props: {
    patientId: {
      type: String,
      required: true,
    },
    planId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const showDialog = ref(false);
    const openDialog = () => {
      showDialog.value = true;
    };

    const onSubmit = async () => {
      emit('plan-completed');
      showDialog.value = false;
    };

    return {
      showDialog,
      openDialog,
      onSubmit,
    };
  },
});
