import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';
import { Plan, PlanFormDto, Step, PlanState, UpdatePlanFormDto } from '@/models/Plan';

let axiosInstance: AxiosInstance;
const storeProfile = useProfileStore();

class PlanService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async create(patientId: string, newPlan: PlanFormDto): Promise<Plan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        newPlan,
      );
      return networkResponse.data as Plan;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(patientId: string, idPlan: string, updatePlan: UpdatePlanFormDto): Promise<Plan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan/${idPlan}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        updatePlan,
      );
      return networkResponse.data as Plan;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAll(patientId: string): Promise<Plan[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<Plan[]>(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[PlanService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[PlanService] Error: request findAll`);
      return handleError(error);
    }
  }

  async findById(patientId: string, id: string): Promise<Plan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<Plan>(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan/${id}?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[PlanService] Succeed : request findById. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[PlanService] Error: request findById`);
      return handleError(error);
    }
  }

  async updateStep(patientId: string, id: string, step: Step): Promise<Plan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.put<Plan>(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan/${id}/step?accountId=${storeProfile.accountId}`,
        { planStep: step.step, completed: step.completed },
        options,
      );

      console.debug(`>[PlanService] Succeed : request updateStep. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[PlanService] Error: request updateStep`);
      return handleError(error);
    }
  }

  async updateState(patientId: string, id: string, state: PlanState): Promise<Plan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.put<Plan>(
        `${process.env.VUE_APP_API_PLAN_SERVICE}/plan/${id}/planState/${state}?accountId=${storeProfile.accountId}`,
        {},
        options,
      );

      console.debug(`>[PlanService] Succeed : request updateState. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[PlanService] Error: request updateState`);
      return handleError(error);
    }
  }
}

export const planService = new PlanService();
