import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "stepper-wrapper" }
const _hoisted_2 = { class: "stepper-item__counter" }
const _hoisted_3 = { class: "stepper-item__name" }

import { PlanState } from '@/models/Plan';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Stepper',
  props: {
  states: {
    type: Array as PropType<PlanState[]>,
    required: true,
  },
  actualStep: {
    type: String,
    required: false,
  },
  allStepsCompleted: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

// Check if a step is the active step
const isActiveStep = (stepText: PlanState, stepIndex: number) => {
  return stepText === props.actualStep || (stepIndex === 0 && !props.actualStep && !props.allStepsCompleted);
};

// Check if a step is completed
const isStepCompleted = (stepIndex: number) => {
  return stepIndex < getCurrentStepIndex() || props.allStepsCompleted;
};

// Get the index of the current step
const getCurrentStepIndex = () => {
  if (props.actualStep) {
    return props.states.findIndex((step) => step === props.actualStep);
  }
  return -1;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.states, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([
        'stepper-item',
        { 'stepper-item--active': isActiveStep(step, index) },
        { 'stepper-item--completed': isStepCompleted(index) },
        { completed: __props.allStepsCompleted },
      ])
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(index + 1), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`plan.states.${step}`)), 1)
      ], 2))
    }), 128))
  ]))
}
}

})