import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-detail__cancel-p" }
const _hoisted_2 = { class: "l-container" }
const _hoisted_3 = { class: "p-grid" }
const _hoisted_4 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Stepper = _resolveComponent("Stepper")!
  const _component_PatientPlanSteps = _resolveComponent("PatientPlanSteps")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_PlanDeleteModal = _resolveComponent("PlanDeleteModal")!
  const _component_PlanFinishModal = _resolveComponent("PlanFinishModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loadingPlanData)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, {
          key: 0,
          "spinner-color": "lavender-700"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_HeadingComponent, {
            title: _ctx.cardTitle,
            "heading-icon": "icon-folder",
            breadcrumb: _ctx.breadcrumb,
            details: _ctx.planHeaderDetails
          }, {
            right: _withCtx(() => [
              _withDirectives(_createElementVNode("div", {
                class: "plan-detail__cancel",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPlanCancelClicked && _ctx.onPlanCancelClicked(...args)))
              }, [
                _cache[1] || (_cache[1] = _createElementVNode("i", {
                  class: "icon-trash_full plan-detail__cancel-icon",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('plan.cancelFollowUp')), 1)
              ], 512), [
                [_vShow, _ctx.canCancelPlan]
              ])
            ]),
            _: 1
          }, 8, ["title", "breadcrumb", "details"]),
          _createElementVNode("main", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_CardComponent, { cardTitle: _ctx.cardTitle }, {
                  cardContent: _withCtx(() => [
                    _createVNode(_component_Stepper, {
                      states: _ctx.statesForStepper,
                      "all-steps-completed": _ctx.currentState === _ctx.PlanState.Completed,
                      "actual-step": _ctx.actualStep
                    }, null, 8, ["states", "all-steps-completed", "actual-step"]),
                    _createVNode(_component_PatientPlanSteps, {
                      "can-modify": _ctx.canModifyPlanSteps,
                      planStepsData: _ctx.planStepsData,
                      onUpdateStepStatus: _ctx.updateStepStatus
                    }, null, 8, ["can-modify", "planStepsData", "onUpdateStepStatus"])
                  ]),
                  _: 1
                }, 8, ["cardTitle"])
              ])
            ])
          ])
        ], 64)),
    (_ctx.plan)
      ? (_openBlock(), _createBlock(_component_PlanDeleteModal, {
          key: 2,
          ref: "planDeleteModalRef",
          planId: _ctx.plan.id,
          patientId: _ctx.patientId,
          onPlanDeleted: _ctx.planDeleted
        }, null, 8, ["planId", "patientId", "onPlanDeleted"]))
      : _createCommentVNode("", true),
    (_ctx.plan)
      ? (_openBlock(), _createBlock(_component_PlanFinishModal, {
          key: 3,
          ref: "planFinishModalRef",
          patientId: _ctx.patientId,
          planId: _ctx.plan.id,
          onPlanCompleted: _ctx.planCompleted
        }, null, 8, ["patientId", "planId", "onPlanCompleted"]))
      : _createCommentVNode("", true)
  ], 64))
}