
import CardComponent from '@/components/CardComponent.vue';
import Stepper from '@/components/common/Stepper.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { useRoute } from 'vue-router';
import { useProfileStore } from '@/store/profile.module';
import dateFormat from '@/helpers/DateFormat.helper';
import { Plan, PlanState, Step } from '@/models/Plan';
import { planService } from '@/services/PlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PatientPlanSteps from './PatientPlanSteps.vue';
import PlanDeleteModal from './PlanDeleteModal.vue';
import PlanFinishModal from './PlanFinishModal.vue';
import router from '@/router';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  components: {
    HeadingComponent,
    CardComponent,
    LoadingComponent,
    Stepper,
    PatientPlanSteps,
    PlanDeleteModal,
    PlanFinishModal,
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();
    const plan = ref<Plan>();
    const storeProfile = useProfileStore();
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const routeBreadcrumb = route.meta.breadcrumb as any[];
    const patientId = route.params.patientId as string;
    const planId = route.params.planId as string;
    const loadingPlanData = ref(false);
    const planStepsData = ref([] as Array<{ state: PlanState; steps: Step[] }>);
    const planDeleteModalRef = ref();
    const planFinishModalRef = ref();

    const canCancelPlan = computed(() => {
      return isAdminUser.value && plan.value?.state !== PlanState.Completed && plan.value?.state !== PlanState.Canceled;
    });

    const canModifyPlanSteps = computed(() => {
      return plan.value?.state !== PlanState.Completed && plan.value?.state !== PlanState.Canceled;
    });

    const statesForStepper = computed(() => {
      return planStepsData.value.map((step) => step.state);
    });

    const actualStep = computed(() => {
      return plan.value?.state;
    });

    onMounted(async () => {
      loadingPlanData.value = true;
      await loadPlanData();
      loadingPlanData.value = false;
    });

    function onPlanCancelClicked() {
      planDeleteModalRef.value?.openDialog();
    }

    async function loadPlanData() {
      const result = await planService.findById(patientId, planId);
      if (!(result instanceof ServiceError)) {
        plan.value = Object.assign({}, result);
        updatePlanStepsData(result.steps);
      }
    }

    function updatePlanStepsData(steps: Step[]) {
      steps.forEach((s: Step) => {
        const index = planStepsData.value.findIndex((psd) => psd.state === s.state);
        if (index === -1) {
          planStepsData.value.push({
            state: s.state,
            steps: [s],
          });
        } else {
          planStepsData.value
            .filter((psd) => psd.state === s.state)
            .forEach((psd) => {
              psd.steps.push(s);
            });
        }
      });
    }

    function updatePlan(planUpdate: Plan) {
      plan.value = planUpdate;
      planStepsData.value = [];
      updatePlanStepsData(planUpdate.steps);
    }

    const breadcrumb = computed(() => {
      if (plan.value) {
        return [
          { label: t(routeBreadcrumb[0].parent), to: `/patient/${patientId}/plans-list` },
          {
            label: t(`plan.types.${plan.value.type}`),
            to: '#',
            disabled: true,
          },
        ];
      }
      return [];
    });

    const cardTitle = computed(() => {
      return plan.value ? t(`plan.types.${plan.value.type}`) : '';
    });

    const planHeaderDetails = computed(() => {
      return [
        {
          icon: '',
          iconLabel: '',
          label: t(`plan.states.${plan.value?.state}`),
        },
        {
          icon: 'icon-calendar',
          iconLabel: t('details.dateAndHour'),
          label: plan.value
            ? dateFormat.formatDateAndHour(dateFormat.formatLocalTimezone(plan.value.creationDate))
            : '',
        },
        {
          icon: 'icon-user',
          iconLabel: t('details.patient'),
          label: t('plan.asigned-specialist') + plan.value?.specialist.name + ' ' + plan.value?.specialist.surname,
        },
      ];
    });

    const currentState = computed(() => plan.value?.state);

    async function planDeleted() {
      await router.replace(`/patient/${patientId}/plans-list`);
    }

    const updateStepStatus = async (step: Step) => {
      if (await stepIsTheLast(step)) {
        planFinishModalRef.value?.openDialog();
      } else {
        callServiceToUpdateStepStatus(step);
      }
    };

    async function callServiceToUpdateStepStatus(stepSelected: Step) {
      stepSelected.completed = !stepSelected.completed;
      const result = await planService.updateStep(patientId, planId, stepSelected);
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditFollowUpStep')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        updatePlan(result);
      }
    }

    async function stepIsTheLast(stepSelected: Step) {
      if (!plan.value?.steps) return false;
      const indexStep = plan.value?.steps.findIndex((step) => step.stepOrder === stepSelected.stepOrder);
      return indexStep === plan.value?.steps.length - 1;
    }

    async function planCompleted() {
      const lastStep = plan.value?.steps[plan.value?.steps.length - 1];
      if (lastStep) {
        await callServiceToUpdateStepStatus(lastStep)
          .then(async () => {
            await router.replace(`/patient/${patientId}/plans-list`);
            toast.add({
              severity: 'success',
              summary: t('messages.notifications.successCompletedFollowUp'),
              life: 3000,
            });
          })
          .catch(() => {
            toast.add({
              severity: 'error',
              summary: `${t('messages.notifications.errorCompletedFollowUp')} ${t('messages.notifications.tryLater')}`,
              life: 3000,
            });
          });
      }
    }

    return {
      plan,
      loadingPlanData,
      cardTitle,
      statesForStepper,
      breadcrumb,
      planStepsData,
      planHeaderDetails,
      currentState,
      actualStep,
      planDeleteModalRef,
      patientId,
      canCancelPlan,
      canModifyPlanSteps,
      onPlanCancelClicked,
      updatePlan,
      planDeleted,
      planCompleted,
      planFinishModalRef,
      updateStepStatus,
      PlanState,
    };
  },
});
