import { UserTableDto } from './User';

export enum PlanStep {
  MedicalTestCreated = 'MEDICAL_TEST_CREATED',
  InitialTestCompleted = 'INITIAL_TEST_COMPLETED',
}

export interface Step {
  state: PlanState;
  step: PlanStep;
  stepOrder: number;
  completed: boolean;
  transitionDate: Date;
}

export enum PlanType {
  Improve = 'IMPROVE',
  Win_1 = 'WIN_1',
  Win_2 = 'WIN_2',
  Win_3 = 'WIN_3',
}

export enum PlanState {
  Started = 'STARTED',
  InProgress = 'IN_PROGRESS',
  Final = 'FINAL',
  PostFinished = 'POST_FINAL',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export class PlanFormDto {
  type: PlanType;
  professionalId: string;

  constructor(plan: Plan) {
    this.type = plan.type;
    this.professionalId = plan.specialist?.professionalId;
  }
}

export class UpdatePlanFormDto {
  professionalId: string;

  constructor(plan: PlanFormDto) {
    this.professionalId = plan.professionalId;
  }
}

export interface Plan {
  id: string;
  patientId: string;
  type: PlanType;
  state: PlanState;
  steps: Step[];
  specialist: UserTableDto;
  creationDate: string;
}
