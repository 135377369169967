import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "form__steps-container" }
const _hoisted_3 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_4 = ["for"]

import { PlanState, Step } from '@/models/Plan';
import { computed, PropType, ref } from 'vue';
import HeadingComponent from '../HeadingComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientPlanSteps',
  props: {
  planStepsData: {
    type: Array as PropType<{ state: PlanState; steps: Step[] }[]>,
    required: true,
  },
  canModify: {
    type: Boolean,
    required: true,
  },
},
  emits: ['update-step-status'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const allStepsOrdered = computed(() => {
  return mutablePlanStepsData.value
    .reduce((a: Step[], b) => {
      const stepSteps = b.steps.map((step: Step) => ({
        ...step,
        stepText: b.state,
      }));
      return [...a, ...stepSteps];
    }, [])
    .sort((a, b) => a.stepOrder - b.stepOrder);
});

const props = __props;

const mutablePlanStepsData = ref(props.planStepsData);

const selectedSteps = computed(() => {
  return mutablePlanStepsData.value.flatMap((item) => item.steps.filter((step: Step) => step.completed === true));
});

const isStepActive = (stepSelected: Step) => {
  if (!props.canModify) {
    return true;
  }

  const index = allStepsOrdered.value.findIndex((step) => step.step === stepSelected.step);
  if (stepSelected.stepOrder === 1 && noStepsCompleted.value) {
    return false;
  }

  if (!stepSelected.completed) {
    const previousStep = allStepsOrdered.value[index - 1];
    return !previousStep?.completed;
  } else {
    const nextStep = allStepsOrdered.value[index + 1];
    return nextStep?.completed;
  }
};

const noStepsCompleted = computed(() => {
  return allStepsOrdered.value.every((s) => s.completed === false);
});

const onValueChanged = (stepChange: Step) => {
  mutablePlanStepsData.value.forEach((step) => {
    const stepFind = step.steps.find((stepOld: Step) => stepOld.step === stepChange.step);
    if (stepFind) {
      emits('update-step-status', stepFind);
    }
  });
};

return (_ctx: any,_cache: any) => {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (props.planStepsData)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.planStepsData, (stepData, indexStepData) => {
            return (_openBlock(), _createElementBlock("div", {
              key: indexStepData,
              class: "form__step-container"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(HeadingComponent, {
                  "sub-heading": true,
                  divider: false,
                  title: _ctx.$t(`plan.states.${stepData.state}`)
                }, null, 8, ["title"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepData.steps, (stepToDo) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: stepToDo.state,
                    class: _normalizeClass(["form__steps-list", { 'c-disabled': isStepActive(stepToDo) && !stepToDo.completed }])
                  }, [
                    _createVNode(_component_Checkbox, {
                      modelValue: selectedSteps.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedSteps).value = $event)),
                      inputId: stepToDo.step,
                      name: stepToDo.state,
                      value: stepToDo,
                      class: "form__steps-choice",
                      disabled: isStepActive(stepToDo),
                      onChange: ($event: any) => (onValueChanged(stepToDo))
                    }, null, 8, ["modelValue", "inputId", "name", "value", "disabled", "onChange"]),
                    _createElementVNode("label", {
                      for: stepToDo.step
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`plan.steps.${stepToDo.step}`)), 1)
                    ], 8, _hoisted_4)
                  ], 2))
                }), 128))
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})